<template>
  <div class="product-detail-container">
    <div v-if="isLoading" class="min-h-screen flex justify-center items-center">
      <loading />
    </div>
    <template v-else>
      <div v-if="showIndent" class="w-full flex flex-col min-h-screen">
        <div class="max-w-rifyo mx-auto">
          <div class="max-xl:px-5 w-full pt-28 pb-8">
            <div class="mb-16 flex align-center" @click="showIndent = false">
              <img
                alt="image"
                src="/playground_assets/arrow-left.svg"
                class="mr-4 cursor-pointer"
              />
              <span class="font-semibold tracking-widest cursor-pointer"
                >Back</span
              >
            </div>
            <h1 class="mb-8 text-4xl font-bold font-lora">Indent Order</h1>
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-3 md:col-span-1">
                <div class="mb-4 font-bold font-lora">Payment Terms</div>
                <div>
                  10% down payment is required for regularly stocked products,
                  whereas, 50% is required for non-regular stocked products.
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div class="mb-4 font-bold font-lora">Delivery Time</div>
                <div>
                  The delivery time for indent order ranging from 2 up to 8
                  months depending on the selected product. Please refer to
                  estimated delivery time information on the product details
                  page.
                </div>
              </div>
              <div class="col-span-3 md:col-span-1">
                <div class="mb-4 font-bold font-lora">Force Majeure</div>
                <div>
                  The estimated delivery time may be adjusted resulting from
                  force majeure condition (for example natural disaster, global
                  supply chain issue, and/or other not mentioned issue that
                  might inflict the production and shipment process directly or
                  indirectly.)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-detail flex-grow w-full bg-[#eae3d9]">
          <div class="max-w-rifyo mx-auto">
            <div class="py-8 max-xl:px-5 w-full">
              <div class="mb-6 text-2xl font-bold font-lora">Product</div>
              <div class="flex">
                <img
                  class="h-32 w-32 mr-5 hidden md:block"
                  :src="finalData.product.photos[0]"
                />
                <div class="flex-grow flex flex-col md:flex-row justify-between">
                  <div class="w-full md:w-2/3 flex flex-col justify-between">
                    <div class="flex flex-row mb-8 md:mb-5">
                      <img
                        class="h-32 w-32 mr-5 md:hidden"
                        :src="finalData.product.photos[0]"
                      />
                      <div class="flex flex-col md:mb-0">
                        <div class="mb-2 text-2xl font-bold font-lora flex-auto">
                          {{ finalData.product.title }}
                        </div>
                        <div class="text-lg">
                          {{ finalPrice }}
                        </div>
                      </div>
                    </div>
                    <div class="grid grid-cols-3 gap-5 mb-8 md:mb-0">
                      <div
                        class="col-span-1"
                        :key="'detail-' + index"
                        v-for="(item, index) in detailData"
                      >
                        <div class="font-bold font-lora">
                          {{ item.title }}
                        </div>
                        <div>
                          {{ getOptionValue(item.title) }}
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="font-bold font-lora">Qty</div>
                        <div>{{ indent.qty }}</div>
                      </div>
                      <div class="col-span-1">
                        <div class="font-bold font-lora">DP</div>
                        <div class="block">{{ downPayment }} %</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/3 flex flex-col">
                    <div class="font-bold font-lora mb-2">Total</div>
                    <div class="text-[22px] font-medium mb-4">
                      {{
                        `${$formatIDR(
                          harga *
                            indent.qty *
                            (downPayment / 100)
                        )}`
                      }}
                    </div>
                    <div
                      class="w-full py-3 bg-[#1f252c] text-white text-center font-semibold tracking-widest cursor-pointer"
                      @click="handleIndentAdd"
                    >
                      Add To Cart
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showAlert" class="flex">
                <div class="h-32 w-32 mr-5"></div>
                <div class="flex-grow flex justify-between">
                  <div class="w-full md:w-1/3 ml-auto text-base text-red mt-2">
                    Sorry, you can't enter products with different types and
                    please complete the transactions that are in the current
                    cart.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div v-if="product_detail" class="w-full bg-[#eae3d9]">
          <div
            class="product-detail-container-header max-w-rifyo w-full mx-auto max-lg:px-5"
          >
            <div class="product-detail-container1 mr-16">
              <router-link
                class="product-detail-container2 flex gap-3 items-center"
                to="/shop/all"
              >
                <img
                  alt="image"
                  src="/playground_assets/arrow-left-black.svg"
                  class="product-detail-image p-0"
                />
                <span class="font-bold text-black font-sans text-base"
                  >Back All Product</span
                >
              </router-link>
              <template v-if="isMultipe">
                <img
                  :alt="product_detail.title"
                  :src="gambar"
                  class="product-detail-image1"
                />
                <div
                  v-if="gambarVariant"
                  class="product-detail-container3 grid grid-cols-5 gap-1.5"
                >
                  <div
                    class="cursor-pointer h-12 lg:h-24"
                    :class="
                      gambar_selected === item
                        ? 'product-detail-active'
                        : 'product-detail-nonactive'
                    "
                    v-for="item in gambarVariant"
                    :key="'selected-' + item.id"
                    @click="selectedGambar(item)"
                  >
                    <img
                      alt="image"
                      :src="item"
                      class="product-detail-image2 w-full h-full object-cover"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <img
                  :alt="product_detail.title"
                  :src="gambar"
                  class="product-detail-image1"
                />
                <div
                  v-if="product_detail.image && product_detail.image.photo"
                  class="product-detail-container3 grid grid-cols-5 gap-1.5"
                >
                  <div
                    class="cursor-pointer h-12 lg:h-24"
                    :class="
                      gambar_selected === item
                        ? 'product-detail-active'
                        : 'product-detail-nonactive'
                    "
                    v-for="item in product_detail.image.photo"
                    :key="'selected-' + item.id"
                    @click="selectedGambar(item)"
                  >
                    <img
                      alt="image"
                      :src="item"
                      class="product-detail-image2 w-full h-full object-cover"
                    />
                  </div>
                </div>
              </template>
              <!-- <div class="product-detail-bullet">
            <div class="product-detail-bullet-active"></div>
            <div class="product-detail-bullet-non-active"></div>
            <div class="product-detail-bullet-non-active1"></div>
            <div class="product-detail-bullet-non-active2"></div>
            <div class="product-detail-bullet-non-active3"></div>
          </div> -->
            </div>
            <div class="product-detail-container4 max-lg:pr-0 lg:pr-8 relative">
              <span class="product-detail-text01 font-sans text-sm">{{
                inStock ? 'IN STOCK' : 'INDENT'
              }}</span>
              <h1
                class="product-detail-text02 max-lg:text-2xl lg:text-4xl font-bold leading-tight"
              >
                {{ productItem.title }}
              </h1>
              <div>
                <div class="w-full flex flex-col">
                  <span
                    v-if="finalPriceDisc !== finalPrice"
                    class="product-detail-text03-d"
                    >{{ finalPriceDisc }}</span
                  >
                  <span
                    class="product-detail-text03 max-lg:text-lg lg:text-2xl"
                    >{{ finalPrice }}</span
                  >
                </div>
              </div>
              <div class="w-custom max-lg:mb-6 lg:mb-32">
                <dropdown-collapse
                  v-for="item in productItem.variant_title"
                  :title="item.title"
                  :key="item.title"
                  :child="item.child"
                  :update-variant="upVariant.bind(item)"
                  :update-toggle="upDropdown.bind(item)"
                  :is-active="dropdownActive === item.title"
                />
              </div>
              <div v-if="showAlert" class="text-base text-red lg:mt-16">
                Sorry, you can't enter products with different types and please
                complete the transactions that are in the current cart.
              </div>
              <div
                class="z-55 max-lg:fixed lg:absolute bottom-0 left-0 ring-0 max-lg:pr-0 lg:pr-8 w-full"
              >
                <div
                  v-if="inStock === 1 && !isOutOfStock"
                  class="md:hidden py-4 text-red text-sm bg-[#f5f5f5] p-5 text-center font-medium"
                >
                   Stok tinggal {{ inStock }} unit, pesan sekarang juga agar tidak kehabisan.
                </div>
                <div
                  v-if="isOutOfStock && inStock"
                  class="md:hidden py-4 text-red text-sm bg-[#f5f5f5] p-5 text-center font-medium"
                >
                  Stok tinggal {{ inStock }} unit
                </div>
                <box-indent
                  ref="boxIndent"
                  :disabled="finalData && finalData.name_product ? false : true"
                  :loading="finalData && finalData.name_product ? false : true"
                  :item="finalData"
                  :stock="inStock"
                  :text1="productItem.title"
                  :text2="finalPrice"
                  @alert="openAlert"
                  @indent="openIndent"
                  @share="share"
                  @error="onOutOfStock"
                />
                <!-- Message out of stock -->
                <div
                  v-if="inStock === 1 && !isOutOfStock"
                  class="hidden md:block py-4 text-red text-sm"
                >
                  Stok tinggal {{ inStock }} unit, pesan sekarang juga agar tidak kehabisan.
                </div>
                <div
                  v-if="isOutOfStock && inStock"
                  class="hidden md:block py-4 text-red text-sm"
                >
                  Stok tinggal {{ inStock }} unit
                </div>
              </div>
            </div>
          </div>
          <div
            class="product-detail-container-body max-xl:px-7 xl:px-36 w-full"
          >
            <div class="max-w-rifyo mx-auto w-full">
              <div class="product-detail-body-header">
                <div class="product-detail-sku">
                  <span class="product-detail-text04">Product SKU</span>
                  <span class="product-detail-text05">
                    {{ sku }}
                  </span>
                </div>
                <div class="product-detail-product">
                  <span class="product-detail-text06">{{ manufaktur }}</span>
                  <span class="product-detail-text07">{{ etalase }}</span>
                </div>
                <div class="product-detail-warranty">
                  <span class="product-detail-text08">Warranty</span>
                  <span class="product-detail-text09">{{
                    productItem.waranty
                  }}</span>
                </div>
                <div class="product-detail-delivery">
                  <span class="product-detail-text10"
                    >Estimated Delivery Time</span
                  >
                  <span class="product-detail-text11">
                    {{ inStock ? delivery_time : delivery_indent }}
                  </span>
                </div>
              </div>
              <div class="product-detail-tabs space-x-4 md:space-x-8">
                <div
                  @click="updateTabs(1)"
                  :class="
                    tabs === 1
                      ? 'product-detail-title-active'
                      : 'product-detail-title'
                  "
                >
                  <span class="product-detail-text font-sans">Description</span>
                </div>
                <div
                  @click="updateTabs(2)"
                  :class="
                    tabs === 2
                      ? 'product-detail-title-active'
                      : 'product-detail-title'
                  "
                >
                  <span class="product-detail-text font-sans">Detail</span>
                </div>
                <div
                  @click="updateTabs(3)"
                  :class="
                    tabs === 3
                      ? 'product-detail-title-active'
                      : 'product-detail-title'
                  "
                >
                  <span class="product-detail-text font-sans">Designer</span>
                </div>
              </div>
              <div class="product-detail-tabs-content">
                <template>
                  <div
                    class="prose max-w-full"
                    style="font-family: Montserrat"
                    v-html="htmlDecode(product_detail.description)"
                    v-if="tabs === 1"
                  ></div>
                </template>
                <div class="product-detail-content w-full" v-if="tabs === 2">
                  <div class="w-full flex flex-wrap -mx-4">
                    <div class="w-full md:w-1/2 px-4">
                      <div
                        class="prose max-w-full"
                        style="font-family: Montserrat"
                        v-html="htmlDecode(product_detail.detail)"
                      ></div>
                    </div>
                    <div class="w-full md:w-1/2 px-4">
                      <div class="flex flex-col space-y-5 items-start">
                        <template v-if="product_detail.brosur !== '[]'">
                          <span class="product-detail-text17">
                            <span class="product-detail-text18"
                              >Download Brochure</span
                            >
                          </span>
                          <div
                            class="product-detail-download"
                            v-for="item in JSON.parse(product_detail.brosur)"
                            :key="'brosur-' + item"
                          >
                            <img
                              alt="image"
                              src="/playground_assets/file.svg"
                              class="product-detail-image7"
                            />
                            <a
                              :href="item"
                              target="_blank"
                              rel="noopener noreferrer"
                              class="product-detail-text19 text-black"
                            >
                              {{ getFileName(item) }}
                            </a>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full" v-if="tabs === 3">
                  <div v-if="product_detail.designer.type === 'two_persons'">
                    <div class="flex flex-col space-y-5">
                      <div
                        class="w-full"
                        v-if="
                          product_detail.designer.info[0] &&
                          product_detail.designer.images[0]
                        "
                      >
                        <div class="w-full flex flex-wrap -mx-4 -mt-5">
                          <div class="w-full md:w-1/4 px-4 pt-5">
                            <img
                              alt="image"
                              :src="product_detail.designer.images[0]"
                            />
                          </div>
                          <div
                            class="w-full md:w-3/4 px-4 pt-5"
                            v-if="product_detail.designer.info[0]"
                          >
                            <div class="w-full font-sans">
                              <div class="mb-8 font-bold font-lora">
                                {{ product_detail.designer.info[0].name }}
                              </div>
                              <div
                                class="mb-8"
                                v-html="
                                  product_detail.designer.info[0].description
                                "
                              ></div>

                              <template
                                v-if="product_detail.designer.info[0].studio"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Office/Studio
                                </div>
                                <div
                                  class="mb-8 studio prose"
                                  v-html="
                                    product_detail.designer.info[0].studio
                                  "
                                ></div>
                              </template>

                              <template
                                v-if="product_detail.designer.info[0].award"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Awards/Recognition
                                </div>
                                <div
                                  class="mb-8 award prose"
                                  v-html="product_detail.designer.info[0].award"
                                ></div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="w-full"
                        v-if="
                          product_detail.designer.info[1] &&
                          product_detail.designer.images[1]
                        "
                      >
                        <div class="w-full flex flex-wrap -mx-4 -mt-5">
                          <div class="w-full md:w-1/4 px-4 pt-5">
                            <img
                              alt="image"
                              :src="product_detail.designer.images[1]"
                            />
                          </div>
                          <div
                            class="w-full md:w-3/4 px-4 pt-5"
                            v-if="product_detail.designer.info[1]"
                          >
                            <div class="w-full font-sans">
                              <div class="mb-8 font-bold font-lora">
                                {{ product_detail.designer.info[1].name }}
                              </div>
                              <div
                                class="mb-8"
                                v-html="
                                  product_detail.designer.info[1].description
                                "
                              ></div>

                              <template
                                v-if="product_detail.designer.info[1].studio"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Office/Studio
                                </div>
                                <div
                                  class="mb-8 studio prose"
                                  v-html="
                                    product_detail.designer.info[1].studio
                                  "
                                ></div>
                              </template>

                              <template
                                v-if="product_detail.designer.info[1].award"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Awards/Recognition
                                </div>
                                <div
                                  class="mb-8 award prose"
                                  v-html="product_detail.designer.info[1].award"
                                ></div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="product_detail.designer.type === 'personal'">
                    <div class="flex flex-col space-y-5">
                      <div
                        class="w-full"
                        v-if="
                          product_detail.designer.info[0] &&
                          product_detail.designer.image
                        "
                      >
                        <div class="w-full flex flex-wrap -mx-4 -mt-5">
                          <div class="w-full md:w-1/4 px-4 pt-5">
                            <img
                              alt="image"
                              :src="product_detail.designer.image"
                            />
                          </div>
                          <div
                            class="w-full md:w-3/4 px-4 pt-5"
                            v-if="product_detail.designer.info[0]"
                          >
                            <div class="w-full font-sans">
                              <div class="mb-8 font-bold font-lora">
                                {{ product_detail.designer.info[0].name }}
                              </div>
                              <div
                                class="mb-8"
                                v-html="
                                  product_detail.designer.info[0].description
                                "
                              ></div>

                              <template
                                v-if="product_detail.designer.info[0].studio"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Office/Studio
                                </div>
                                <div
                                  class="mb-8 studio prose"
                                  v-html="
                                    product_detail.designer.info[0].studio
                                  "
                                ></div>
                              </template>

                              <template
                                v-if="product_detail.designer.info[0].award"
                              >
                                <div class="mb-8 font-bold font-lora">
                                  Awards/Recognition
                                </div>
                                <div
                                  class="mb-8 award prose"
                                  v-html="product_detail.designer.info[0].award"
                                ></div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sc-product-review" v-if="product_review.length">
                <div class="product-detail-buyer-text">
                  <h1 class="product-detail-text21 max-lg:text-4xl lg:text-5xl">
                    Buyer Review
                  </h1>
                  <span class="product-detail-text22">
                    What our buyers think about this product
                  </span>
                </div>
                <div class="loading-review" v-if="loadingReview">
                  <loading />
                </div>
                <div class="w-full grid grid-cols-2 gap-4 md:gap-12">
                  <div
                    class="col-span-2 md:col-span-1 py-4 border-t border-gray-200"
                    :key="'col-' + index"
                    v-for="(item, index) in product_review"
                  >
                    <div :ref="`review${index}`">
                      <div class="w-full flex justify-between">
                        <template v-if="item.user_info">
                          <div class="text-lg font-bold font-lora">
                            {{ item.user_info.name }}
                          </div>
                        </template>
                        <template v-else>
                          <div class="text-lg font-bold font-lora">
                            John Doe
                          </div>
                        </template>
                        <div class="flex-none flex">
                          <img
                            class="h-6 w-6"
                            :src="
                              index < item.rate
                                ? '/playground_assets/star-on.svg'
                                : '/playground_assets/star-off.svg'
                            "
                            :key="'thumb-' + index"
                            v-for="(star, index) in 5"
                          />
                        </div>
                      </div>
                      <div class="mb-2">
                        {{ $formatDate(item.created_at, 'MMMM YYYY') }}
                      </div>
                      <div class="mb-2 flex gap-2" v-if="item.photo.length">
                        <img
                          class="flex-none object-cover"
                          :key="'image-' + i"
                          :src="img.photo"
                          style="height: 74px; width: 74px"
                          v-for="(img, i) in item.photo"
                        />
                      </div>
                      <div>{{ item.review }}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="w-full pb-8 md:pb-24 md:pt-8 pt-0 flex flex-col md:flex-row justify-center gap-4"
                >
                  <button
                    v-if="params.page > 1"
                    type="button"
                    :disabled="loadingReview"
                    class="px-4 py-2.5 border border-black hover:bg-black justify-center"
                    @click="getProductReview(params.page - 1, params.limit)"
                  >
                    <span class="btn__text">Prev Reviews</span>
                  </button>

                  <button
                    v-if="product_review.length >= params.limit"
                    type="button"
                    :disabled="loadingReview"
                    class="px-4 py-2.5 border border-black hover:bg-black justify-center"
                    @click="getProductReview(params.page + 1, params.limit)"
                  >
                    <span class="btn__text">Next Reviews</span>
                  </button>
                </div>

              </div>
              <div
                class="sc-product-related"
                v-if="
                  product_detail.product_section &&
                  product_detail.product_section.length > 0
                "
              >
                <div class="product-detail-buyer-text1">
                  <h1
                    class="product-detail-text25 max-lg:text-4xl lg:text-5xl text-center leading-relaxed"
                  >
                    Complete<span
                      class="max-lg:block lg:inline-block max-lg:ml-0 lg:ml-2"
                      >Your Product</span
                    >
                  </h1>
                </div>
                <div
                  class="grid grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-10 xl:gap-16"
                >
                  <card-complete
                    v-for="(item, index) in product_detail.product_section"
                    :key="'section-product-' + index"
                    :image_src="item.image"
                    :title="item.title"
                    :hargaNormal="$formatIDR(item.price)"
                    :item="item"
                    simpleCard
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full py-8 lg:py-20"
            v-if="recommended && recommended.length > 0"
          >
            <div class="max-w-rifyo mx-auto max-lg:px-5 w-full">
              <h1
                class="product-detail-text25 max-lg:text-4xl lg:text-5xl text-center"
              >
                Recommendation
              </h1>
              <div
                class="product-detail-shop-list grid grid-cols-2 lg:grid-cols-4 gap-4"
              >
                <shop-card
                  rootClassName="px-4 pb-4"
                  v-for="item in recommended"
                  :key="'indent-' + item.id"
                  :image_src="item.image.thumbnail"
                  :title="item.title"
                  :item="item"
                  simpleCard
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <Footer />
  </div>
</template>

<script>
  import DropdownCollapse from '../components/dropdown-collapse';
  import BoxIndent from '../components/box-indent';
  import Footer from '../components/footer';
  import BuyerReview from '../components/buyer-review';
  import ShopCard from '../components/shop-card';
  import CardComplete from '../components/card-complete';
  import Api from '../api';
  import _ from 'lodash';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'ProductDetail',
    props: {},
    data() {
      return {
        harga: null,
        discount: null,
        sku: '-',
        delivery_time: '-',
        delivery_indent: '-',
        gambar: '',
        isMultipe: false,
        gambarVariant: [],
        gambar_selected: null,
        detailData: [],
        finalData: null,
        tabs: 1,
        tabContent: null,
        manufaktur: null,
        etalase: null,
        products: [],
        product_detail: null,
        variants: [],
        dropdownActive: null,
        product_review: [],
        loadingReview: false,
        downPayment: 0,
        reviews: [],
        recommended: [],
        randoms: [],
        indent: null,
        showIndent: false,
        isLoading: false,
        showAlert: false,
        isOutOfStock: false,
        params: {
          page: null,
          limit: null,
        },
      };
    },
    methods: {
      async getRandomProducts() {
        try {
          const response = await this.$http.get(
            `${Api.baseUrl}/product?perpage=9999`
          );
          if (response.data.status) {
            let array = response.data.data.filter((product) => {
              return product.id !== this.product_detail.id;
            });
            let random = [];
            for (let index = 0; index < 4; index++) {
              const i = Math.floor(Math.random() * array.length);
              random.push(array[i]);
              array.splice(i, 1);
            }
            this.randoms = random;
          }
        } catch (error) {
          throw error;
        }
      },
      async getRecommended() {
        const slug = this.product_detail.category_slug;
        try {
          const response = await this.$http.get(
            `${Api.baseUrl}/product?category_slug=${slug}`
          );
          if (response.data.status) {
            const array = response.data.data;
            this.recommended = array
              .filter((product) => {
                return product.id !== this.product_detail.id;
              })
              .slice(0, 4);
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      async share() {
        // try {
        //   await navigator.share({
        //     title: "Rifyo's Product",
        //     text: this.product_detail.title,
        //     url: window.location.href,
        //   });
        // } catch (err) {
        //   console.error(err);
        //   alert(
        //     "Your browser doesn't support this share function. Try again on another browser."
        //   );
        // }
      },

      getOptionValue(name) {
        if (this.variants.length) {
          return this.variants.filter((option) => option.title == name)[0].name;
        } else {
          return 'No data';
        }
      },

      async getDP() {
        const {
          data: { data },
        } = await axios.get(`${Api.downPaymentUrl}`);
        this.downPayment = data.value;
      },

      async handleIndentAdd() {
        if (this.$store.state.isAuthenticated) {
          if (this.carts?.product.length > 0) {
            this.showAlert = true;
          } else {
            const content = await axios.post(Api.cartUrl, {
              product_id: this.finalData.product_id,
              qty: this.indent.qty,
            });
            await this.$store.dispatch('getCart');
            this.$router.push('/cart');
          }
        } else {
          this.$router.push('/login');
        }
      },

      async openIndent(indent) {
        await this.getDP();
        this.indent = indent;
        this.showIndent = true;
      },

      async openAlert() {
        this.showAlert = true;
      },

      getFileName(res) {
        if (res) {
          const sp = res.split('/');
          return sp[sp.length - 1].replaceAll('_', ' ');
        }
        return this.product_detail.title.replaceAll('_', ' ');
      },

      async getProduct(category_id) {
        try {
          let fullUrl = Api.productUrl + `&perpage=4&page=1`;
          if (category_id) {
            fullUrl = fullUrl + `&category_id=${category_id}`;
          }
          const response = await this.$http.get(fullUrl);
          this.products = response.data.data;
        } catch (error) {
          console.log(error);
        }
      },

      async getProductDetail(slug) {
        try {
          let fullUrl = Api.productDetailUrl + `${slug}`;
          const response = await this.$http.get(fullUrl);
          this.product_detail = response.data.data;
          this.gambar = this.product_detail.image.photo[0];
          this.gambarVariant = this.product_detail.image.photo[0];
        } catch (error) {
          throw error;
        }
      },

      async getProductReview(page, limit) {
        const id = this.product_detail.id;
        try {
          this.loadingReview = true;
          const response = await this.$http.get(
            `${Api.baseUrl}/product/${id}/review?page=${page}&perpage=${limit}`,
          );
          this.loadingReview = false;
          this.product_review = response.data.data;

          this.params = {
            page: response.data.current_page,
            limit: response.data.per_page,
          }
        } catch (error) {
          this.loadingReview = false;
        }
      },

      htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
      },

      updateTabs(tabs) {
        this.tabs = tabs;
        if (tabs === 1) {
          this.tabContent = this.htmlDecode(this.finalData.product.description);
        } else if (tabs === 2) {
          this.tabContent = this.htmlDecode(this.finalData.product.detail);
        } else if (tabs === 3) {
          this.tabContent = null;
        }
      },

      upDropdown(text) {
        this.dropdownActive = text;
      },

      upVariant(variant) {
        const indexSelectedVariant = this.productItem.variant_title.findIndex(
          (item) => item.title == variant.title
        );
        const nextTitle = this.productItem.variant_title[
          indexSelectedVariant + 1
        ]
          ? this.productItem.variant_title[indexSelectedVariant + 1].title
          : null;

        if (nextTitle) {
          this.dropdownActive = nextTitle;
        }

        this.variants = [
          ...this.variants.filter((res) => res.title !== variant.title),
          variant,
        ];
        const res = _.uniqBy(this.productItem.product_variants, function (e) {
          return e.title;
        });

        if (res.length === this.variants.length) {
          let ArrGroupByid = [];
          let i = 0;
          this.productItem.product_variants.forEach((r) => {
            ArrGroupByid[i] = {
              ...r,
              name_product: this.productItem.product_variants
                .filter((f) => f.product_id === r.product_id)
                .map((f) => f.name),
            };
            i++;
          });
          const params = this.variants.map((f) => f.name);
          const result = ArrGroupByid.find(
            (f) => f.name_product.sort().toString() === params.sort().toString()
          );
          if (result) {
            // final data variant by title & value
            this.finalData = result;
            this.sku = this.finalData.product.sku;
            this.delivery_time = this.finalData.product.delivery_time;
            this.delivery_indent = this.finalData.product.delivery_indent;
            this.discount = this.finalData.product.price;
            if (this.finalData.product.is_multiple === true) {
              this.isMultipe = true;
              this.gambar = this.finalData.product.photos[0];
              this.gambarVariant = this.finalData.product.photos;
            } else {
              this.gambar = this.finalData.product.photos[0];
              this.gambarVariant = this.finalData.product.photos[0];
            }
            this.manufaktur = this.finalData.product.manufacture;
            this.etalase = this.finalData.product.etalase;

            // Harga
            if (this.finalData.discount_type === 'percent') {
              this.harga =
                this.finalData.product.price -
                (this.finalData.product.price *
                  parseInt(this.finalData.maximum_decimal)) /
                  100;
            } else if (this.finalData.discount_type === 'fixed') {
              this.harga =
                this.finalData.product.price -
                parseInt(this.finalData.maximum_decimal);
            } else {
              this.harga = this.finalData.product.price;
            }
          }
          this.updateTabs(1);
        }
        this.updateTabs(1);
      },

      selectedGambar(item) {
        this.gambar_selected = item;
        this.gambar = item;
      },

      hargaRendah(item) {
        return Math.min(
          ...item.product_variants.map((variant) => variant.product.price)
        );
      },

      hargaTinggi(item) {
        return Math.max(
          ...item.product_variants.map((variant) => variant.product.price)
        );
      },

      hargaRendahDisc(item) {
        return Math.min(
          ...item.product_variants.map((variant) => {
            var item = 0;
            if (variant.discount_type === 'percent') {
              item =
                variant.product.price -
                (variant.product.price * parseInt(variant.maximum_decimal)) /
                  100;
            } else if (variant.discount_type === 'fixed') {
              item = variant.product.price - parseInt(variant.maximum_decimal);
            } else {
              item = variant.product.price;
            }

            return item;
          })
        );
      },

      hargaTinggiDisc(item) {
        return Math.max(
          ...item.product_variants.map((variant) => {
            var item = 0;
            if (variant.discount_type === 'percent') {
              item =
                variant.product.price -
                (variant.product.price * parseInt(variant.maximum_decimal)) /
                  100;
            } else if (variant.discount_type === 'fixed') {
              item = variant.product.price - parseInt(variant.maximum_decimal);
            } else {
              item = variant.product.price;
            }

            return item;
          })
        );
      },

      initPriceRange(product) {
        let price = 0;
        if (this.hargaRendah(product) !== this.hargaTinggi(product)) {
          price =
            this.$formatIDR(this.hargaRendah(product)) +
            ' - ' +
            this.$formatIDR(this.hargaTinggi(product));
        } else {
          price = this.$formatIDR(this.hargaRendah(product));
        }
        this.discount = price;
      },

      initPriceRangeDisc(product) {
        let price = 0;
        if (this.hargaRendahDisc(product) !== this.hargaTinggiDisc(product)) {
          price =
            this.$formatIDR(this.hargaRendahDisc(product)) +
            ' - ' +
            this.$formatIDR(this.hargaTinggiDisc(product));
        } else {
          price = this.$formatIDR(this.hargaRendahDisc(product));
        }
        this.harga = price;
      },

      async getCart() {
        try {
          const {
            data: { data },
          } = await axios.get(Api.cartUrl);
          const cartLength = data.product.length + data.product_indent.length;
          this.$store.commit('setCount', cartLength);
          this.$store.commit('setGlobalCart', data);
        } catch (error) {
          throw error;
        }
      },

      async getAllData(slug) {
        try {
          this.isLoading = true;
          await this.getProductDetail(slug);
          // await this.getReviews();
          await this.getRecommended();
          await this.getRandomProducts();
          this.initPriceRange(this.productItem);
          this.initPriceRangeDisc(this.productItem);
          this.dropdownActive = this.productItem.variant_title[0]?.title;
          this.finalData = this.productItem.product_variants[0];
          this.manufaktur = this.finalData.product.manufacture;
          this.etalase = this.finalData.product.etalase;
          this.updateTabs(1);
          this.getProduct(this.finalData.product.category_id);
          this.getProductReview(1, 4);
          // check cart
          if (this.isAuthenticated) {
            await this.getCart();
          }
        } catch (error) {
          throw error;
        } finally {
          this.isLoading = false;
        }
      },

      onOutOfStock({ stock, jumlah }) {
        if (stock < jumlah) {
          this.isOutOfStock = true;
        } else {
          this.isOutOfStock = false;
        }
      },
    },

    async created() {
      await this.getAllData(this.$route.params.slug);
    },

    computed: {
      inStock() {
        if (this.finalData?.name_product) {
          return this.finalData.product.stock;
        } else {
          const array = this.product_detail.product_variants;
          if (array) {
            let stock = 0;
            for (let index = 0; index < array.length; index++) {
              const element = array[index];
              stock += element.product.stock;
            }
            return stock;
          }
        }
      },
      productItem() {
        const data = this.product_detail?.product_variants;
        // let newArr = [];
        // for (let index = 0; index < data?.length; index++) {
        //   const element = data[index];
        //   newArr = newArr.concat(element);
        // }
        let newArr2 = data
          .filter((v, i, a) => a.findIndex((t) => t.title === v.title) === i)
          .map((t) => {
            return {
              title: t.title,
              child: data
                .filter((v) => v.title === t.title)
                .filter(
                  (v, i, a) => a.findIndex((t) => t.name === v.name) === i
                ),
            };
          });
        this.detailData = newArr2;
        return {
          ...this.product_detail,
          // product_variants_new: newArr,
          // product_variants_thumb: newArr.filter((t, i) => i % 2 === 0),
          variant_title: newArr2,
        };
      },
      finalPrice() {
        if (typeof this.harga === 'string') {
          return this.harga;
        } else {
          return this.$formatIDR(this.harga);
        }
      },
      finalPriceDisc() {
        if (typeof this.discount === 'string') {
          return this.discount;
        } else {
          return this.$formatIDR(this.discount);
        }
      },
      carts() {
        return this.$store.state.carts;
      },
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
    },
    watch: {
      async '$route.params.slug'(newSlug) {
        if (newSlug) {
          await this.getAllData(newSlug);
          console.log(newSlug);
        }
      },
    },
    components: {
      DropdownCollapse,
      BoxIndent,
      BuyerReview,
      Footer,
      ShopCard,
      Loading,
      CardComplete,
    },
  };
</script>

<style>
  .btn__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.24em;
    text-transform: capitalize;
    color: #1F252C;
  }

  button:hover > .btn__text {
    color: #fff;
  }

  /* div.product-detail-container-body
    div.product-detail-tabs-content
    div.mb-8.award
    ul {
    list-style: disc;
  } */
  .product-detail-tabs-content .award.prose p,
  .product-detail-tabs-content .studio.prose p {
    margin: 0;
  }
  .prose p b,
  .Default span {
    font-family: 'Lora';
    color: #1f252c;
  }
  .prose div span {
    line-height: 24px;
    color: #1f252c;
  }
  .prose table {
    width: 100% !important;
  }
  .prose table tr > td {
    width: 50%;
  }
  @media (max-width: 767px) {
    .prose table tr {
      display: flex;
      flex-direction: column;
    }
    .prose table tr > td {
      width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    .shop-card-image1 {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
    .product-recomendation .shop-card-image1 {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
</style>

<style scoped>
  .w-custom {
    width: 100%;
  }

  .product-recomendation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eae3d9;
    width: 100%;
    padding: 80px;
    padding: 80px;
  }
  .loading-review {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-detail-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-container-header {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-top: 120px;
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-fiveunits); */
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fiveunits);
    background-color: #eae3d9;
  }
  .product-detail-container1 {
    flex: 0 0 auto;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .product-detail-image {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
  }
  .product-detail-image1 {
    width: 100%;
    object-fit: cover;
    height: 570px;
  }
  .product-detail-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    overflow: hidden;
  }
  .product-detail-active {
    flex: 0 0 auto;
    /* width: 19%; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: #d9d9d9; */
    background-color: #fff;
    overflow: hidden;
    position: relative;
    /* margin-right: 10px; */
  }
  .product-detail-active:after {
    content: '';
    position: absolute;
    z-index: 9;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .product-detail-image2 {
    object-fit: cover;
    object-position: center center;
    height: 100px;
    width: 100%;
  }
  .product-detail-nonactive {
    flex: 0 0 auto;
    /* width: 19%; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;
    /* margin-right: 10px; */
  }
  .product-detail-image3 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive1 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image4 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive2 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image5 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-nonactive3 {
    flex: 0 0 auto;
    width: 19%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-image6 {
    width: 100px;
    object-fit: cover;
  }
  .product-detail-bullet {
    width: 100%;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .product-detail-bullet-active {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #1f252c;
  }
  .product-detail-bullet-non-active {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active1 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active2 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-bullet-non-active3 {
    flex: 0 0 auto;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    border-radius: 50%;
    flex-direction: column;
    background-color: #aea79d;
  }
  .product-detail-container4 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text01 {
    font-weight: 500;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #1f252c;
    margin-bottom: 8px;
  }
  .product-detail-text02 {
    color: #1f252c;
    font-family: Lora;
    margin-bottom: 16px;
  }
  .product-detail-text03 {
    color: #1f252c;
    font-size: 24px;
    line-height: 32px;
    font-family: Montserrat;
    margin-bottom: 16px;
  }
  .product-detail-text03-d {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    color: #1f252c;
    opacity: 0.6;
    font-size: 18px;
  }
  .product-detail-container-body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-fourunits);
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-sixunits); */
    flex-direction: column;
    background-color: #fff;
  }
  .product-detail-body-header {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 64px;
  }
  .product-detail-sku {
    flex: 0 0 auto;
    width: 35%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text04 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text05 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-product {
    flex: 0 0 auto;
    width: 25%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text06 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text07 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-warranty {
    flex: 0 0 auto;
    width: 15%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text08 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .subtitle {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .product-detail-text09 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-delivery {
    flex: 0 0 auto;
    width: 25%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #1f252c;
  }
  .product-detail-text10 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .product-detail-text11 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .product-detail-tabs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #eae3d9;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    cursor: pointer;
  }
  .product-detail-title {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prose ul > li {
    padding: 0;
    padding-bottom: 16px;
  }
  .prose ul > li::before {
    background-color: #374151;
  }
  .product-detail-title-active {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    border-bottom: 2px solid #1f252c;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    /* font-weight: 700; */
  }

  .product-detail-title1 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text14 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
    letter-spacing: 3.4px;
  }
  .product-detail-tabs-content {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .product-detail-content {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text15 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text16 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .product-detail-list {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-list-item {
    width: 100%;
  }
  .product-detail-list1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-list2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-content1 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-text17 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .product-detail-text18 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .product-detail-download {
    flex: 0 0 auto;
    border: 1px solid #000;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
  }
  .product-detail-image7 {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text19 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
    letter-spacing: 3.5px;
  }
  .product-detail-download1 {
    flex: 0 0 auto;
    border: 1px solid #000;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
  }
  .product-detail-image8 {
    width: 24px;
    object-fit: cover;
  }
  .product-detail-text20 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
    letter-spacing: 3.5px;
  }
  .product-detail-buyer-text {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text21 {
    font-family: Lora;
    font-size: 48px;
    font-weight: bold;
  }
  .product-detail-text22 {
    margin-top: var(--dl-space-space-halfunit);
    font-family: Montserrat;
  }
  .product-detail-container-review {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }
  .product-detail-review-left {
    flex: 0 0 auto;
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-review-right {
    flex: 0 0 auto;
    width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-detail-container-rows {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 24px;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;
  }
  .product-detail-btn-details {
    flex: 0 0 auto;
    width: 200px;
    border: 1px solid #1f252c;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text23 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 2.5px;
  }
  .product-detail-text24 {
    font-family: Montserrat;
  }
  .product-detail-buyer-text1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: center;
  }
  .product-detail-text25 {
    font-family: Lora;
    font-size: 48px;
    font-weight: bold;
  }
  .product-detail-shop-list {
    width: 100%;
    margin-top: 45px;
  }
  @media (max-width: 991px) {
    .product-detail-sku {
      margin-bottom: 32px;
    }
    .product-detail-product {
      margin-bottom: 32px;
    }
    .product-detail-warranty {
      margin-bottom: 32px;
    }
    .product-detail-delivery {
      margin-bottom: 32px;
    }
    .w-custom {
      margin-left: -1.75rem;
      width: calc(100% + 3.5rem);
    }
    .product-detail-image1 {
      width: 100%;
      object-fit: cover;
      height: 300px;
    }
    .product-detail-container-header {
      height: 100%;
      flex-wrap: wrap;
      padding-top: 100px;
      /* padding-left: var(--dl-space-space-unit); */
      /* padding-right: var(--dl-space-space-unit); */
    }
    .product-detail-container1 {
      width: 100%;
      height: 100%;
    }
    .product-detail-image {
      padding-top: 4px;
    }
    .product-detail-container3 {
      display: none;
    }
    .product-detail-bullet {
      display: flex;
    }
    .product-detail-container4 {
      order: 2;
      width: 100%;
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-left: 0px;
    }
    .product-detail-text01 {
      font-size: 20px;
      font-style: normal;
      font-family: Lora;
      font-weight: 700;
      letter-spacing: 0;
    }
    .product-detail-container-body {
      height: auto;
      padding-top: 60px;
      /* padding-top: var(--dl-space-space-unit); */
      /* padding-left: var(--dl-space-space-unit); */
      /* padding-right: var(--dl-space-space-unit); */
    }
    .product-detail-body-header {
      flex-wrap: wrap;
    }
    .product-detail-sku {
      width: 100%;
    }
    .product-detail-product {
      width: 100%;
    }
    .product-detail-warranty {
      width: 100%;
    }
    .product-detail-delivery {
      width: 100%;
    }
    .product-detail-tabs-content {
      flex-wrap: wrap;
    }
    .product-detail-content {
      width: 100%;
    }
    .product-detail-content1 {
      width: 100%;
      margin-top: var(--dl-space-space-oneandhalfunits);
    }
    .product-detail-container-review {
      flex-wrap: wrap;
    }
    .product-detail-review-left {
      width: 100%;
    }
    .product-detail-review-right {
      width: 100%;
    }
    .product-detail-shop-list {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
</style>
